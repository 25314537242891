import React from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Button, Flex, Icon, Text, Title } from "../../auxiliaries"

/** Utils */
import {
  CLEAR_100,
  DARK_900,
  PURPLE_100,
  PURPLE_300,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Utils */
import locales from "./locales/Card.json"

const contentMarginTopActive = device =>
  device === "desktop" ? "3.555555556vw" : ""
const contentMarginTop = device => (device === "desktop" ? "3.25vw" : "")

const getActiveIconHeight = device =>
  device === "desktop" ? "8.333333333vw" : "19.323671498vw"
const getIconHeight = device =>
  device === "desktop" ? "6.944444444vw" : "19.323671498vw"

const Card = ({ active, title, description, icon, link }) => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Wrapper
      active={active}
      device={device}
      direction="column"
      justifyContent="space-between"
      gap="20px"
    >
      <Flex
        justifyContent="center"
        mt={device === "desktop" ? "0px" : "-30px"}
      >
        <Icon
          name={active ? `${icon}-filled` : icon}
          height={active ? getActiveIconHeight(device) : getIconHeight(device)}
          width={active ? getActiveIconHeight(device) : getIconHeight(device)}
        />
      </Flex>
      <Flex
        direction="column"
        mt={active ? contentMarginTopActive(device) : contentMarginTop(device)}
        mb={device === "desktop" ? "2.22222222vw" : "0vw"}
      >
        <Title
          color={active ? DARK_900 : CLEAR_100}
          fontSize={device === "desktop" ? "1.0416666666666667vw" : "5.7971vw"}
          fontFamily="Raleway"
          weight="black"
        >
          {title}
        </Title>
        <Text
          color={active ? DARK_900 : CLEAR_100}
          fontSize={device === "desktop" ? "0.8333333333333334vw" : "3.8647vw"}
          fontFamily="Raleway"
          maxWidth={device === "desktop" ? "12.777777778vw" : "100%"}
          mt={device === "desktop" ? "1.3889vw" : "3.8647vw"}
        >
          {description}
        </Text>
      </Flex>
      {device === "desktop" && (
        <Flex justifyContent="flex-end">
        </Flex>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  background-color: ${({ active }) => (active ? PURPLE_300 : PURPLE_100)};
  height: ${({ active, device }) => getWrapperHeight(active, device)};
  width: ${({ device }) => ["mobile", "tablet", "laptop"].includes(device) && "51.207729469vw"};
`

const getWrapperHeight = (active, device) => {
  if (active) {
    return device === "desktop" ? "25vw" : "61.594202899vw"
  }

  return device === "desktop" ? "23.680555556vw" : "61.594202899vw"
}

export default Card
