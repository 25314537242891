import React, { useState } from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Card from "./Card"
import Slider from "../Slider"

/** Locales */
import locales from './locales/Card.json'

const OSList = () => {
  const device = useDeviceType()
  const lang = useLang()

  //eslint-disable-next-line
  const [active, setActive] = useState(Math.floor(locales[lang]['ITEMS'].length / 2))

  if (!device) return null

  const onActiveChange = (item, index) => {
    setActive(index)
  }

  return (
    <Flex direction="column" justifyContent="center" mt={device === "desktop" ? "13.8889vw" : "50.2415vw"}>
      <Container
        direction="column"
        delimiter={device === "desktop" ? "54.027777778vw" : "80.6763vw"}
        mx="auto"
      >
        <Title
          fontSize={device === "desktop" ? "4em" : "9.6618vw"}
          fontFamily="Raleway"
          weight="black"
          textAlign={device === 'desktop' && 'center'}
        >
          {locales[lang]['TITLE']}
        </Title>
        <Text
          fontSize={device === "desktop" ? "1.111111111vw" : "3.8647vw"}
          mt={device === "desktop" ? "2.2222vw" : "5.7971vw"}
          mb={device === "desktop" ? "3.888888889vw" : "7.7295vw"}
        >
          {locales[lang]['DESCRIPTION']}
        </Text>
      </Container>
      <SliderWrapper device={device} justifyContent="center" height={device === 'desktop' ? '32.7778vw' : '99.275362319vw'}>
        <Slider
          component={Card}
          items={locales[lang]['ITEMS']}
          onItemClick={onActiveChange}
          activePadding={{
            px: device === "desktop" ? "3.611111111vw" : "9.6618vw",
            py: device === "desktop" ? "3.8889vw" : "18.84057971vw",
          }}
          padding={{
            px: device === "desktop" ? "2.2222vw" : "9.6618vw",
            py: device === "desktop" ? "2.777777778vw" : "18.84057971vw",
          }}
          emptyItemWidth="70.5314vw"
        />
      </SliderWrapper>
    </Flex>
  )
}

const SliderWrapper = styled(Flex)`
  position: ${({ device }) => device === 'mobile' && 'relative'};
  overflow-x: ${({ device }) => device === 'mobile' && 'hidden'};

  & > div {
    position: ${({ device }) => device === 'mobile' && 'absolute'};
  }
`

export default OSList
