import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { PURPLE_100, PURPLE_300 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { Flex } from "../../auxiliaries"
import { Appear, Disappear } from "../../auxiliaries/Mixins"

const Slider = ({
  component: Component,
  emptyItemWidth,
  activePadding,
  padding,
  items,
  onItemClick,
}) => {
  const [displayItems, setDisplayItems] = useState([])
  const [animating, setAnimating] = useState(true)
  const [active, setActive] = useState(0)
  const device = useDeviceType()

  useEffect(() => {
    setActive(Math.floor(items.length / 2))
    setDisplayItems(
      items.map((item, index) => ({ ...item, sourceIndex: index }))
    )
  }, [items])

  useEffect(() => {
    if (!!animating) {
      setTimeout(() => {
        setAnimating(false)
      }, 800)
    }
  }, [animating])

  if (!device) return null

  const handleChange = (item, index) => {
    if (active !== index) {
      onItemClick(item, index)
      setActive(index)
      setAnimating(true)
    }
  }

  const createList = list =>
    list.map(item => (
      <Item
        key={item.sourceIndex}
        animating={animating}
        active={active === item.sourceIndex}
        onClick={() => handleChange(items[item.sourceIndex], item.sourceIndex)}
        px={active === item.sourceIndex ? activePadding.px : padding.px}
        py={active === item.sourceIndex ? activePadding.py : padding.py}
      >
        <Component {...{ ...item, active: active === item.sourceIndex }} />
      </Item>
    ))

  return (
    <>
      <Flex alignItems="center" gap={device === "desktop" ? "4.1667vw" : "5.7971vw"}>
        {active === items.length - 1 &&
          (
            <>
              {createList(displayItems.slice(active - (device === 'desktop' ? 2 : 1), active + 1))}
              {["mobile", "tablet", "laptop"].includes(device) && <EmptyItem width={emptyItemWidth} />}
            </>
          )
        }
        {active > 0 &&
          active < items.length - 1 &&
          createList(displayItems.slice(active - 1, active + 2))}
        {active === 0 && (
          <>
            {["mobile", "tablet", "laptop"].includes(device) && <EmptyItem width={emptyItemWidth} />}
            {createList(displayItems.slice(active, active + (device === 'desktop' ? 3 : 2)))}
          </>
        )}
      </Flex>
    </>
  )
}

const Item = styled(Flex)`
  background-color: ${({ active }) => (active ? PURPLE_300 : PURPLE_100)};
  animation: ${({ active, animating }) =>
    animating &&
    css`
      ${Disappear} 350ms, ${Appear} 350ms
    `};
`

const EmptyItem = styled(Flex)``

export default Slider
