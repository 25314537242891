import React from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Button, Flex, Text } from "../../auxiliaries"

/** Utils */
import { transformToCurrency } from "../../../utils/functional/parsers"
import {
  CLEAR_100,
  GRAY_650,
  PURPLE_500,
  YELLOW_100,
  YELLOW_500,
  GRAY_700
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"

/** Locales */
import locales from "./locales/Card.json"
import { navigate } from "@reach/router"

const InfoTag = ({ device, title, description }) => (
  <Flex direction="column" gap={device === "desktop" ? "0.138888889vw" : "0vw"}>
    <Text
      fontSize={device === "desktop" ? "1.3889vw" : "5.7971vw"}
      weight="black"
    >
      {title}
    </Text>
    <Text
      color={GRAY_650}
      fontSize={device === "desktop" ? "0.833333333vw" : "2.8986vw"}
    >
      {description}
    </Text>
  </Flex>
)

const Card = ({
  lang = "PT",
  memory,
  vcpuQty,
  transferRate,
  storage,
  value,
  valueFirstMonth,
  url,
}) => {
  const device = useDeviceType()

  if (!device) return null

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      gap={device === "desktop" ? "1.3889vw" : "8.6957vw"}
      bgColor={YELLOW_100}
      height={device === "desktop" ? "28.270833333vw" : "105.618357488vw"}
      width={device === "desktop" ? "22.972222222vw" : "80.6763vw"}
      px={device === "desktop" ? "4.166666667vw" : "9.6618vw"}
      py={device === "desktop" ? "1.222222222vw" : "7.729468599vw"}
    >
      <Flex
        alignItems="flex-end"
        gap={device === "desktop" ? "0.833333333vw" : "3.8647vw"}
      >
        <Text
          color={YELLOW_500}
          fontSize={device === "desktop" ? "6.944444444vw" : "19.323671498vw"}
          weight="black"
        >
          {memory}
        </Text>
        <Flex direction="column" mb={device === "desktop" ? "1.7vw" : "1vw"}>
          <Text
            fontSize={device === "desktop" ? "1.388888889vw" : "5.7971vw"}
            fontFamily="Raleway"
            weight="black"
          >
            {locales[lang]["GB"]}
          </Text>
          <Text
            color={GRAY_650}
            fontSize={device === "desktop" ? "0.833333333vw" : "2.898550725vw"}
          >
            {locales[lang]["OF_MEMORY"]}
          </Text>
        </Flex>
      </Flex>
      <Info
        device={device}
        justifyContent="space-between"
        gap={device === "desktop" ? "2.777777778vw" : ""}
        width={device === "mobile" ? "100%" : ""}
      >
        <InfoTag device={device} title={`${vcpuQty}vCPU`} description="vCPUs" />
        <InfoTag device={device} title={transferRate} description="Banda" />
        <InfoTag device={device} title={storage} description="SSD Disk" />
      </Info>
      <Flex gap={device === "desktop" ? "2vw" : "5vw"}>
        <Flex
          direction="column"
          alignItems="center"
          gap={device === "desktop" ? "0.1389vw" : "0vw"}
        >
          <Text
            color={YELLOW_500}
            fontSize={device === "desktop" ? "1.3020833333333333vw" : "5.7971vw"}
            weight="black"
          >
            {transformToCurrency(valueFirstMonth)}
          </Text>
          <Text
            color={GRAY_650}
            fontSize={device === "desktop" ? "0.833333333vw" : "2.898550725vw"}
          >
            {locales[lang]["FIRST_MONTH"]}
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={device === "desktop" ? "0.1389vw" : "0vw"}
        >
          <Text
            color={GRAY_700}
            fontSize={device === "desktop" ? "1.3020833333333333vw" : "5.7971vw"}
            weight="bold"
          >
            {transformToCurrency(value)}
          </Text>
          <Text
            color={GRAY_650}
            fontSize={device === "desktop" ? "0.833333333vw" : "2.898550725vw"}
          >
            {locales[lang]["MONTHLY"]}
          </Text>
        </Flex>
      </Flex>
      <Flex mt={device === "desktop" ? "0.833333333vw" : "0vw"}>
        <Button
          bgColor={PURPLE_500}
          px={device === "mobile" && "5.7971vw"}
          py={device === "mobile" && "4.347826087vw"}
          onClick={() => navigate(url)}
        >
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "16px"}
            weight="bold"
            color={CLEAR_100}
          >
            {locales[lang]["SIGN_PLAN"]}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

const Info = styled(Flex)`
  padding-bottom: ${({ device }) => (device === "desktop" ? "1.3889vw" : "")};
  border-bottom: 1px solid ${YELLOW_500};
`

export default Card
