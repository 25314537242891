import React, { useState } from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Button, Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import {
  CLEAR_100,
  GRAY_650,
  PURPLE_300,
  PURPLE_500,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Card from "./Card"

/** Locales */
import locales from "./locales/Card.json"

const cardsXEON = [
  {
    memory: 5,
    vcpuQty: 2,
    transferRate: "2TB",
    storage: "20GB",
    value: 49.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/xeon-2",
  },
  {
    memory: 10,
    vcpuQty: 4,
    transferRate: "4TB",
    storage: "30GB",
    value: 99.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-4",
  },
  {
    memory: 15,
    vcpuQty: 6,
    transferRate: "6TB",
    storage: "40GB",
    value: 139.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-6",
  },
  {
    memory: 20,
    vcpuQty: 8,
    transferRate: "8TB",
    storage: "50GB",
    value: 189.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-8",
  },
  {
    memory: 25,
    vcpuQty: 10,
    transferRate: "10TB",
    storage: "60GB",
    value: 229.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-10",
  },
  {
    memory: 30,
    vcpuQty: 12,
    transferRate: "12TB",
    storage: "70GB",
    value: 279.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-12",
  },
  {
    memory: 35,
    vcpuQty: 14,
    transferRate: "14TB",
    storage: "80GB",
    value: 329.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-14",
  },
  {
    memory: 40,
    vcpuQty: 16,
    transferRate: "16TB",
    storage: "100GB",
    value: 369.99,
    url: "https://billing.enx.host/index.php/store/vps-xeon/vps-xeon-16",
  },
]

const cardsRYZEN = [
  {
    memory: 4,
    vcpuQty: 2,
    transferRate: "2TB",
    storage: "20GB",
    value: 99.90,
    valueFirstMonth: 16,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=136&billingcycle=monthly",
  },
  {
    memory: 8,
    vcpuQty: 4,
    transferRate: "4TB",
    storage: "30GB",
    value: 199.90,
    valueFirstMonth: 32,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=137&billingcycle=monthly",
  },
  {
    memory: 12,
    vcpuQty: 6,
    transferRate: "6TB",
    storage: "40GB",
    value: 299.90,
    valueFirstMonth: 48,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=138&billingcycle=monthly",
  },
  {
    memory: 16,
    vcpuQty: 8,
    transferRate: "8TB",
    storage: "50GB",
    value: 399.90,
    valueFirstMonth: 64,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=139&billingcycle=monthly",
  },
  {
    memory: 20,
    vcpuQty: 10,
    transferRate: "10TB",
    storage: "60GB",
    value: 499.90,
    valueFirstMonth: 80,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=140&billingcycle=monthly",
  },
  {
    memory: 24,
    vcpuQty: 12,
    transferRate: "12TB",
    storage: "70GB",
    value: 599.90,
    valueFirstMonth: 96,
    url: "https://enxadahost.com/members/cart.php?a=add&pid=141&billingcycle=monthly",
  },
]

const Pricing = () => {
  const device = useDeviceType()
  const lang = useLang()

  const [plans, setPlans] = useState("VPS_RYZEN")

  if (!device) return null

  return (
    <Flex
      id="pricing"
      direction="column"
      alignItems="center"
      mt={device === "desktop" ? "13.888888889vw" : "50.241545894vw"}
      mb={device === "desktop" ? "11.111111111vw" : "23.429951691vw"}
    >
      <Container
        delimiter={device === "desktop" ? "49.097222222vw" : "80.676328502vw"}
        justifyContent={device === "desktop" && "center"}
      >
        <Title
          fontSize={device === "desktop" ? "4em" : "9.6618vw"}
          fontFamily="Raleway"
          weight="black"
          textAlign={device === "desktop" && "center"}
          mb={device === "desktop" ? "2.2222vw" : "5.7971vw"}
        >
          {locales[lang]["TITLE"]}
        </Title>
      </Container>
      <Container
        delimiter={device === "mobile" && "80.676328502vw"}
        justifyContent={device === "desktop" && "center"}
      >
        <Text
          color={GRAY_650}
          fontSize={device === "desktop" ? "1.111111111vw" : "3.8647vw"}
          textAlign={device === "desktop" && "center"}
          mb={device === "desktop" ? "4.1667vw" : "15.458937198vw"}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Container>
      <Container
        delimiter={device === "mobile" && "80.676328502vw"}
        justifyContent="center"
        mb={device === "desktop" ? "4.1667vw" : "15.458937198vw"}
      >
        <Button
          bgColor={plans === "VPS_RYZEN" ? PURPLE_500 : PURPLE_300}
          px={device === "mobile" && "5.7971vw"}
          py={device === "mobile" && "4.347826087vw"}
          onClick={() => setPlans("VPS_RYZEN")}
        >
          <Text
            fontFamily="Open Sans"
            fontSize={device === "mobile" ? "3.8647vw" : "18px"}
            weight="bold"
            color={plans === "VPS_RYZEN" ? "#000" : CLEAR_100}
          >
            {locales[lang]["VPS_RYZEN"]}
          </Text>
        </Button>
        {/* <Button
          bgColor={plans === "VPS_XEON" ? PURPLE_500 : PURPLE_300}
          px={device === "mobile" && "5.7971vw"}
          py={device === "mobile" && "4.347826087vw"}
          onClick={() => setPlans("VPS_XEON")}
        >
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "16px"}
            color={plans === "VPS_XEON" ? "#000" : CLEAR_100}
            weight="bold"
          >
            {locales[lang]["VPS_XEON"]}
          </Text>
        </Button> */}
      </Container>
      <CardGrid device={device}>
        {plans === "VPS_XEON"
          ? cardsXEON.map(card => <Card key={card} {...card} lang={lang} />)
          : cardsRYZEN.map(card => <Card key={card} {...card} lang={lang} />)}
      </CardGrid>
    </Flex>
  )
}

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ device }) =>
    device === "desktop" ? "repeat(3, 1fr)" : "1fr"};
  gap: ${({ device }) => (device === "desktop" ? "2.1667vw" : "4.7971vw")};
`

export default Pricing
